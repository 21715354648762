<template>
  <footer
    class="bg-royal-blue-160 text-white"
    data-testid="app-footer">
    <div class="flex flex-col items-center justify-between px-4 py-4 lg:container md:px-8 lg:mx-auto lg:flex-row lg:justify-between lg:py-8"
    >
      <ul class="flex w-full flex-row justify-center border-b border-white border-opacity-40 pb-4 lg:w-auto lg:justify-start lg:border-0 lg:border-opacity-0 lg:pb-0"
      >
        <li>
          <a
            class="mr-4 border-r border-white border-opacity-40 pr-4 font-bold hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.rsc.org/help-legal/"
          >Legal</a
          >
        </li>
        <li>
          <a
            class="mr-4 border-r border-white border-opacity-40 pr-4 font-bold hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.rsc.org/help-legal/legal/privacy/"
          >Privacy</a
          >
        </li>
        <li>
          <a
            class="font-bold hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.rsc.org/help-legal/legal/accessibility/"
          >Accessibility</a
          >
        </li>
      </ul>
      <div class="pt-4 text-center lg:py-0">
        <div class="flex flex-col md:flex-row">
          <span class="md:mr-4 md:border-r md:border-white md:border-opacity-40 md:pr-4"
          >{{ copyrightMessage }}</span
          >
          <span>Registered charity number 207890</span>
        </div>
      </div>
    </div>
    <div class="bg-science-blue-5 text-royal-blue-160">
      <div class="px-4 py-4 text-center lg:container md:px-8 lg:mx-auto">
        <div
          data-testid="onetrust-cookie-footer-non-br"
          id="rsc-onetrust-cookie-footer-non-br"
        >
          This website collects cookies to deliver a better user experience.
          <span id="rsc-onetrust-cookie-footer-global">
            See how this site uses
            <router-link
              class="text-royal-blue-100 underline visited:text-visited hover:text-royal-blue-160 hover:no-underline focus:text-royal-blue-160 focus:no-underline"
              to="#"
            >cookies</router-link
            >.
            <!-- should this be a a:link to ensure cookie page is refreshed and content is rendered? -->
          </span>
          <span
            id="rsc-onetrust-cookie-footer-ca"
            style="display: none">
            <router-link
              class="text-royal-blue-100 underline visited:text-visited hover:text-royal-blue-160 hover:no-underline focus:text-royal-blue-160 focus:no-underline"
              id="linkCookiesCa"
              to="#"
            >Do not sell my personal data</router-link
            >.
            <!-- should this be a a:link to ensure cookie page is refreshed and content is rendered? -->
          </span>
        </div>
        <div
          data-testid="onetrust-cookie-footer-br"
          id="rsc-onetrust-cookie-footer-br"
          style="display: none"
        >
          Este site coleta cookies para oferecer uma melhor experiência ao
          usuário.
          <span>
            Veja como este site usa
            <router-link
              class="text-royal-blue-100 underline visited:text-visited hover:text-royal-blue-160 hover:no-underline focus:text-royal-blue-160 focus:no-underline"
              id="linkCookiesBr"
              to="#"
            >cookies</router-link
            >.
            <!-- should this be a a:link to ensure cookie page is refreshed and content is rendered? -->
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    copyrightMessage() {
      return '© Royal Society of Chemistry ' + new Date().getFullYear()
    },
  },
}
</script>

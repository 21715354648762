import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "componentTitle" }
const _hoisted_3 = { class: "card-text mg-top" }
const _hoisted_4 = { class: "card-text" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "componentTitle" }
const _hoisted_7 = { class: "card-text mg-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isVerified)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.emailVerifiedTitle), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.emailVerifiedMessage), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.closeTab), 1)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.errorHeadingText), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.emailCouldNotVerifiedText), 1)
      ]))
}
<script>
export default {
  props: {
    width: {
      type: [String, Number],
      default: '1.5rem',
    },
    height: {
      type: [String, Number],
      default: '1.5rem',
    },
    title: {
      type: String,
      default: 'Warning triangle',
    },
    isDecorative: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ariaLabel() {
      return this.isDecorative ? null : this.title
    },
  },
}
</script>
<template>
  <svg
    role="img"
    :aria-label="ariaLabel"
    :aria-hidden="isDecorative"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :style="{ width, height }"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{{ title }}</title>
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M13.196 2.257a1.423 1.423 0 0 0-.501-.553A1.298 1.298 0 0 0 12 1.5c-.244 0-.484.07-.694.204-.21.133-.384.324-.502.553L1.92 19.828a1.549 1.549 0 0 0 .02 1.452c.12.22.293.401.499.528.206.126.44.192.678.192h17.767c.237 0 .47-.066.677-.192.206-.127.379-.308.5-.528a1.545 1.545 0 0 0 .019-1.452L13.196 2.257ZM12 14.25v-6"
    />
    <circle
      cx="12"
      cy="18"
      r="1"
      fill="currentColor" />
  </svg>
</template>

<template>
  <section class="errors-container">
    <div class="errors-list">
      <div
        v-for="error in errors.filter((err: Error) => err.type === errorType)"
        class="error-item"
        :key="error.id"
      >
        <div class="error-message" v-html="error.message"></div>
      </div>
    </div>
  </section>
</template>
<style lang="sass">
.errors-container
    display: flex
    align-items: center
    margin-bottom: 2.5rem
    width:100%
.errors-container:has(.error-item)
    background-color: $royal-blue-20
    padding: 1rem
    border-radius: 4px
    opacity: 0.9
.errors-container:has(.error-item)::before
  content: url("~@/assets/images/warning.svg")
  position: relative
  float: left
.errors-list
    display: flex
    flex-direction: column
    width: 100%
.error-item
    display: flex
    justify-content: space-between
    padding-left: 1rem
    width: 100%
    margin-bottom: 0.5rem
.error-message
    margin-right: 2rem
</style>
<script lang="ts">
import { ErrorsService } from "@/core/services/errorsService";
import { PropType, defineComponent } from "vue";
import { Error, ErrorType } from "@/core/models/metadata/error";
import { ref } from "vue";
import Cookies from 'js-cookie';
import { JwtWrapper } from '@/core/services/jwtWrapper';

export default defineComponent({
  name: "ErrorsManager",
  props: {
    errorType: { required: true, type: String as PropType<ErrorType> },
  },
  created() {
    scriptBuilder();
  },
  data() {
    return {
      errorsProperty: ref(ErrorsService.getAll()),
    };
  },
  computed: {
    errors(): Error[] {
      return this.errorsProperty;
    },
  },
});

const scriptBuilder = () => {
  
  //if cookie has been dropped before on this domain, assume user has consented to GA via onetrust at a different domain
  if (Cookies.get('_ga'))
  {
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.textContent = `function OptanonWrapper() { }`;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";

      script2.textContent = `window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
             clientName: '${JwtWrapper.jwtClaims.clientId}',
             clientID: '${JwtWrapper.jwtClaims.clientName }'
        });
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${process.env.VUE_APP_GTM_AUTH}&gtm_preview=${process.env.VUE_APP_GTM_PREVIEW}&gtm_cookies_win=${process.env.VUE_APP_GTM_COOKIES_WIN}';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.VUE_APP_GTM_ID}');`;

      document.head.insertBefore(script1, document.head.firstChild);
      script1.insertAdjacentElement("afterend",script2);
  }
}
</script>

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/rsc-logo.svg'
import _imports_1 from '@/assets/images/arrowNext.svg'


const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "main" }
const _hoisted_4 = { class: "main-wrapper" }
const _hoisted_5 = { class: "main" }
const _hoisted_6 = { class: "cardMainContent" }
const _hoisted_7 = { class: "main" }
const _hoisted_8 = { class: "cardMainContent" }
const _hoisted_9 = { class: "needHelpTitle" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "card-link-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ApplicationFooter = _resolveComponent("ApplicationFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("main", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "cardHeader" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Royal Society of Chemistry",
                  title: "Royal Society of Chemistry",
                  class: "logo-img"
                })
              ], -1)),
              _createVNode(_component_router_view)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.needHelp), 1),
              _createElementVNode("a", {
                href: _ctx.contactFormUri,
                class: "contact-form-link card-link-text"
              }, [
                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.contactCS), 1),
                _cache[1] || (_cache[1] = _createElementVNode("img", {
                  src: _imports_1,
                  alt: "Return icon",
                  class: "card-link-icon"
                }, null, -1))
              ], 8, _hoisted_10)
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_ApplicationFooter)
  ]))
}
<template>
    <div v-if="isVerified">
        <h2 class="componentTitle">{{ emailVerifiedTitle }}</h2>
        <p class="card-text mg-top">{{ emailVerifiedMessage }}</p>
        <p class="card-text">{{ closeTab }}</p>
    </div>
    <div v-else>
        <h2 class="componentTitle">{{ errorHeadingText }}</h2>
        <p class="card-text mg-top">{{ emailCouldNotVerifiedText }}</p>
    </div>

</template>
<script lang="ts">
import { Resources } from '@/core/models/metadata/resources';
import { Services } from '@/core/models/metadata/services';
import { inject } from 'vue';
import { defineComponent } from 'vue';


export default defineComponent({
    data(){
        const resources = inject(Services.Resources) as Resources;
        return {
            emailVerifiedTitle: resources.titles.emailVerified,
            emailVerifiedMessage: resources.messages.emailVerifiedClose,
            closeTab:resources.messages.closeTab,
            errorHeadingText: resources.errors.errorHeading,
            emailCouldNotVerifiedText: resources.errors.emailCouldNotVerified,
            isVerified: false,
        };
    },
    mounted() {
    this.checkVerificationStatus();
  },
    methods: {
        checkVerificationStatus() {
      const urlParams = new URLSearchParams(window.location.search);
      const success = urlParams.get('success');
      this.isVerified = success === 'true';
    },
  },
})
</script>
<style lang="sass" scoped>
 
.mg-top
 margin-top: 10px 
</style>
<template>
  <div class="app-container">
    <div class="container">
      <div class="main">
        <div class="main-wrapper">
          <main class="main">
            <div class="cardMainContent">
              <div class="cardHeader">
                <img src="@/assets/images/rsc-logo.svg" alt="Royal Society of Chemistry"
                  title="Royal Society of Chemistry" class="logo-img" />
              </div>
              <router-view />
            </div>
          </main>
          <div class="main">
            <div class="cardMainContent">
              <h2 class="needHelpTitle">{{ needHelp }}</h2>
              <a :href="contactFormUri" class="contact-form-link card-link-text">
                <p class="card-link-text">{{ contactCS }}</p>
                <img src="@/assets/images/arrowNext.svg" alt="Return icon" class="card-link-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ApplicationFooter />
  </div>
</template>
<script lang="ts">
import { JwtWrapper } from './core/services/jwtWrapper';
import { defineComponent, inject } from 'vue';
import { Services } from './core/models/metadata/services';
import { Resources } from './core/models/metadata/resources';
import Cookies from 'js-cookie';
import ApplicationFooter from '@/components/shared/AppFooter.vue';

export default defineComponent({
  created() {
    scriptBuilder();
    const { state, session_token } = this.$route.query as { state: string, session_token: string };
    JwtWrapper.tryPersist(state, session_token, this.$router);
  },
  data() {
    const resources = inject(Services.Resources) as Resources;
    return {
      needHelp: resources.messages.needHelp,
      contactCS: resources.messages.contactCS,
      contactFormUri: process.env.VUE_APP_USER_MANAGEMENT_SITE_CONTACT_FORM_URL
    }
  },
  components: {
    ApplicationFooter
    }
})

const scriptBuilder = () => {
  
  //if cookie has been dropped before on this domain, assume user has consented to GA via onetrust at a different domain
  if (Cookies.get('_ga'))
  {
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.textContent = `function OptanonWrapper() { }`;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";            

      script2.textContent = `window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
             clientName: '${JwtWrapper.jwtClaims.clientId}',
             clientID: '${JwtWrapper.jwtClaims.clientName }'
        });
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${process.env.VUE_APP_GTM_AUTH}&gtm_preview=${process.env.VUE_APP_GTM_PREVIEW}&gtm_cookies_win=${process.env.VUE_APP_GTM_COOKIES_WIN}';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.VUE_APP_GTM_ID}');`;

      document.head.insertBefore(script1, document.head.firstChild);
      script1.insertAdjacentElement("afterend",script2);
  }
}
</script>

<style lang="sass">
@import './core/styles/common.sass'
@import './assets/main.css';
</style>
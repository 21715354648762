/* eslint-disable */
import { Resources } from "../models/metadata/resources";
import { Title } from "../models/metadata/title";
import { StringHelper } from "./stringHelper";

export class ValidationRulesService {
  private readonly lettersRegex: RegExp = /^[\x20-\x7E\xA0-\xFFŠŠšŒœŽžŸ]*$/u;
  private readonly resources!: Resources;
  private readonly stringHelper!: StringHelper;
  private validationRules!: {
    title: ((value: string) => string | boolean)[];
    firstName: ((value: string) => string | boolean)[];
    lastName: ((value: string) => string | boolean)[];
  };
  private possibleTitles: string[] = Object.values(Title);

  constructor(resources: Resources, stringHelper: StringHelper) {
    this.resources = resources;
    this.stringHelper = stringHelper;
  }

  private commonFirstAndLastNameValidation = (value: string, nameType: string) => this.commonNameValidation(value, nameType, 150, 0);
  private commonNameValidation(value: string, nameType: string, max: number, min: number): string | true {
    if (!value) return this.stringHelper.formatValues(this.resources.messages.validationPleaseEnter, [nameType]);

    if (value.length > max) return this.stringHelper.formatValues(this.resources.messages.validationNumbCharacters, [this.resources.messages.max, max]);

    if (!this.lettersRegex.test(value)) return this.resources.messages.validationOnlyLetters;

    return true;
  }

  public getValidationRules(): {
    title: ((value: string) => string | boolean)[];
    firstName: ((value: string) => string | boolean)[];
    lastName: ((value: string) => string | boolean)[];
  } {
    if (this.validationRules) return this.validationRules;
    this.validationRules = {
      title: [(value: string) => !!(this.possibleTitles.indexOf(value) + 1) || this.resources.errors.titleNotValid],
      firstName: [(value: string) => this.commonFirstAndLastNameValidation(value, this.resources.messages.firstName)],
      lastName: [(value: string) => this.commonFirstAndLastNameValidation(value, this.resources.messages.lastName)],
    };
    return this.validationRules;
  }
}

<script>
export default {
  props: {
    width: {
      type: [String, Number],
      default: '1.5rem',
    },
    height: {
      type: [String, Number],
      default: '1.5rem',
    },
    title: {
      type: String,
      default: 'Chevron down',
    },
    isDecorative: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ariaLabel() {
      return this.isDecorative ? null : this.title
    },
  },
}
</script>
<template>
  <svg
    role="img"
    :aria-label="ariaLabel"
    :aria-hidden="isDecorative"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :style="{ width, height }"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{{ title }}</title>
    <path
      d="M20 8L12.3769 15.8393C12.3274 15.8902 12.2687 15.9306 12.204 15.9582C12.1393 15.9858 12.07 16 12 16C11.93 16 11.8607 15.9858 11.796 15.9582C11.7313 15.9306 11.6726 15.8902 11.6231 15.8393L4 8"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<template>
  <div>
    <h2 class="componentTitle">{{ emailTitle }}</h2>
    <p class="card-text">{{ validationEmailSent }}</p>
    <p class="card-text-email" :title="userEmail">{{ userEmail }}</p>
    <p class="card-text">{{ clickTheLink }}</p>
    <p class="card-text">
      {{ verifyYourEmail }} <span><a href="#" class="email-verification-link"
          @click.prevent="sendEmailVerificationRequest">
          {{ emailText }}.
        </a>
      </span>
    </p>
    <ErrorsManager :errorType="errorType"></ErrorsManager>
    <div>
      <div v-if="isLoading">
        <RadialSpinner></RadialSpinner>
      </div>
      <button class="card-link-text back-button" @click="navigateBack()">
      {{ back }}
    </button>
    </div>
  </div>
</template>
<script lang="ts">

import { Resources } from "@/core/models/metadata/resources";
import { ResendEmailVerificationService } from "@/core/services/resendEmailVerificationService";
import RadialSpinner from "@/components/shared/RadialSpinner.vue";
import ErrorsManager from "@/components/shared/ErrorsManager.vue";
import { ErrorType } from "@/core/models/metadata/error";
import { defineComponent, inject } from "vue";
import { Services } from "@/core/models/metadata/services";
import { JwtWrapper } from "@/core/services/jwtWrapper";
import { EmailVerificationCheckerService } from "@/core/services/emailVerificationCheckerService";

var emailVerificationService: ResendEmailVerificationService;
var emailVerificationCheckerService: EmailVerificationCheckerService;
export default defineComponent({
  created() {
    emailVerificationCheckerService = inject(Services.EmailVerificationChecker) as EmailVerificationCheckerService;
    emailVerificationCheckerService.start(this.$router);
  },
  data() {
    let resources = inject(Services.Resources) as Resources;
    emailVerificationService = inject(Services.ResendEmailVerification) as ResendEmailVerificationService;
    return {
      emailText: resources.buttons.resendEmail,
      emailTitle: resources.titles.verifyYourEmail,
      validationEmailSent: resources.messages.validationEmailSent,
      verifyYourEmail: resources.messages.verifyYourEmail,
      clickTheLink: resources.messages.clickTheLink,
      back: resources.buttons.back,
      userEmail: JwtWrapper.jwtClaims?.email,
      isLoading: false,
      errorType: ErrorType.ResendEmailVerification,
    };
  },
  methods: {
    async sendEmailVerificationRequest() {
      this.isLoading = true;
      await emailVerificationService.handle();
      this.isLoading = false;
    },
    navigateBack(){
      window.location.href = JwtWrapper.navigateToPasswordScreen();
    }
  },
  components: {
    RadialSpinner,
    ErrorsManager,
  },
});
</script>

<style lang="sass" scoped>
.email-verification-link 
  color: #0077DB
.back-button 
 color: #004976
 font-size: 1.15rem

.email-verification-link:hover
  text-decoration: underline

.wrapper
  gap: 70px

.card-text
  margin: 0 0 1.5rem

.card-text-email
  font-size: 1.15rem
  font-weight: 700
  margin: 0 0 1.5rem
  text-overflow: ellipsis
  overflow: hidden

.card-text-note
  margin: 0 0 3rem 0

@media all and (max-width: 800px)
  /* .mid-wrapper
    width: 100% */
  .wrapper
    gap: 0

  .aside-left
    min-width: 100%

  .aside-right
    min-width: 100%
    margin: 0 0 2rem

  .componentTitle
    font-size: 1.5rem
    line-height: 2.5rem
</style>
